import React from 'react';
import { Geolocation } from '@capacitor/geolocation';
import { IonFooter, IonButton, IonBackButton, IonSplitPane, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Capgeo.css';
import MapQuestButton from '../components/MapQuestButton';
import { GoogleMap } from '@capacitor/google-maps';
//const apiKey = 'YOUR_API_KEY_HERE';
//const mapRef = document.getElementById('map');

const printCurrentPosition = async () => {
  const coordinates = await Geolocation.getCurrentPosition();

  console.log('Current position:', coordinates);
}


const Geo: React.FC = () => {
  return (
    <IonPage>
          <IonHeader className="GLIHEADER3">
              <IonToolbar className="GLIHEADER3"><div><IonTitle className="GLIHEADER3">GeoPage</IonTitle><IonButton className="GLILOCBUTTON3" routerLink="/home" slot="end">Back</IonButton><MapQuestButton></MapQuestButton></div></IonToolbar>
          </IonHeader>
          <IonContent><div><iframe id="mapq" className="GLIFRAME3" src="mapquestframe.html"> </iframe></div></IonContent> 
<IonFooter className="GLIFOOTER3">GeoHome - Greenville Associates Consulting</IonFooter>          
</IonPage>
  );
};
//getandsetmapqueststring();

function getandsetmapqueststring()
{
  const X = localStorage.getItem("Mapquest");
  //document.getElementById("mapq").src = Xstringurl;
}


//const newMap = await GoogleMap.create({
  //id: 'my-map', // Unique identifier for this map instance
  //element: mapRef, // reference to the capacitor-google-map element
  //apiKey: apiKey, // Your Google Maps API Key
  //config: {
  //  center: {
      // The initial position to be rendered by the map
  //    lat: 33.6,
  //    lng: -117.9,
    //},
    //zoom: 8, // The initial zoom level to be rendered by the map
  //},
//});
export default Geo;
